import { Slide } from "react-awesome-reveal"

function About (){

    return(
        <div id="about-section">
            <Slide direction="up" className="about-slide">
                <div id="about-container">
                    <p>Üdvözöllek! Peti vagyok,<br/> örülök, hogy rátaláltál az oldalamra. Szenvedélyemnek tekintem a kreatív tartalmak létrehozását. Az élet minden pillanata inspirációt jelent számomra. Az általam készített fotók és videók egyedi hangulattal és látvánnyal rendelkeznek, melyek képesek magukkal ragadni a nézőt. Mindig nagy figyelmet fordítok arra, hogy az elkészült anyagok valóban kifejezzék a Te elképzeléseidet, és egyedi stílusommal további értéket adjanak a projekteknek. Az egyedi igényeket mindig nyitottsággal és lelkesedéssel fogadom, hiszen számomra a legfontosabb, hogy elégedett legyél az eredménnyel. Remélem, hogy hamarosan találkozunk és, alkothatok valami egyedit neked is!</p>
                </div>
            </Slide>
        </div>
    )
}

export default About